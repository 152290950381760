.dash-head{
    /* color:red; */
    /* margin-bottom: 0px; */
    font-size: 140%;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;   
    height: auto;
}
.margin-bottom-0{
    margin-bottom: -15px;
}
.dash-head1{
    /* color:red; */
    font-weight: bold;
    
}
.selector{
    padding-left: 15%;
    justify-content: space-between;
    padding-right: 15%;
    width: 50%;
}
.table-margin{
    margin-left: 20px;
    margin-right: 20px;
    width: 95%;
}
.heading-dash{
    display: flex;
    align-items: center;
    align-content: center;
    height: 7%;
}
.table-Bottom {
    margin-left: 20px;
    margin-bottom: 0px;
    width: 45%;
}
.dash-main{
    padding: 1%;
}
.paichart{
    padding: 3%;
    padding-left: 0%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}
.bartable{
    padding: 3%;
    padding-right: 0%;
    width: 50%;
}

.chart-container {
    width: 100%;
  }
  
  .chart-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .active-value {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .active-value span {
    font-weight: bold;
  }
  
  .value {
    margin-left: 10px;
    color: #555;
  }
  
  .recharts-wrapper {
    overflow: visible; /* This prevents the blank space */
  }
  