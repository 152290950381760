.page{
    
    height: 800px;
  
}
.zeroPadding {
    padding-left: 0;
    padding-right: 0;
}
.form1 {
   
    width: 40%;
    text-align: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;

}

/* 
input{
    border: 1px solid;
} */


.data {
    text-align: right;
}
/* .btn1{
    margin: 10px;
    border: .5px solid;
    padding: auto;
    font-size: small;
    border-radius: 10px;
    background-color: red;
    color: aliceblue   ;
} */
.form2{
    margin: 20px ;   
    padding: 1%;
    display: flex;
    justify-content: center;
    border:none;
    border-bottom: .5px solid grey;
}
.btn2 {
    /* color: white;
    background-color: red;
    border: red; */

    margin: 2px;  
    height: 30px;
    margin-left: 1px;
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 40%;

    z-index: 1;
}

@media only screen and (min-width: 768px) {
    /* .btn {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

    } */
  
}

@media only screen and (max-width: 768px) {
    .data {
        text-align: left;
    }

    .form1 {
        width: 100%;
        margin-left: 5px;
        margin-right: 10px;
    }
    .form2 {
        width: 90%;
    }

    .align {
        display: flex;
        flex-direction: row;
    }

    .date {
        width: 30%;
    }

    /* .btn {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    
    } */
}   
