.bgdiv {
    background-color: lightgrey;
    /* height: 100vh; */
}
/* Add responsive styles */
@media only screen and (max-width: 768px) {
    .leftdiv {
      display: none; /* Hide the left image on smaller screens */
    }
  }
  
  @media only screen and (max-width: 576px) {
    .card {
      margin-top: 20px; /* Add some margin to the card on smaller screens */
    }
  }
  
.instruction{
    margin: 0;
    margin-top: 1%;
    padding-top: 9%;
    margin-bottom: -9%;
    border-top: .1px solid rgb(243, 239, 239);
    font-size: 80%;
}
.input-height{
    width: 100%;
    font-size: 80%;
}
@media only screen and (min-width: 768px) {
    .bothcards {
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: center;

    }
    

    .cardalign {
        display: flex;
        /* margin-top: 20%; */
        margin-left: 23%;
    }

    .leftdiv {
        /* background-color: red; */
        /* width: 35vh; */
        height: 55vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rightdiv {
        /* background-color: white; */
        width: 35vh;
        height: 55vh;
        text-align: center;
    }

    .login-r-img {
        width: 80%;
        margin: 10%;
    }

    .leftimg {
        width: 30vh;
        margin-top: 13vh;
        margin-left: 20px;
    }

    .upperimg {
        margin-top: 5vh;
    }

    .form-control-lg {
        /* border: none; */
        /* border-bottom: 1px solid grey; */
        border-radius: 0;
        

    }
    .center{
        display: flex;
        justify-content: center;
    }
}

/* mobile view vss */
@media only screen and (max-width: 768px) {
    .bothcards {
        display: flex;
        flex-direction: column;

    }

    .cardalign {
        display: flex;
        /* margin-top: 20%; */
        margin-left: 23%;
    }

    .leftdiv {
        display: none;
    }

    .rightdiv {
        background-color: white;
        width: 4vh;
        height: 55vh;
        text-align: center;
        border-radius: 29px;
        border: .5px solid grey;
    }

    .login-r-img {
        width: 80%;
        margin: 10%;
    }

    .leftimg {
        width: 30vh;
        margin-top: 13vh;
        margin-left: 20px;
    }

    .upperimg {
        margin-top: 5vh;
    }

    .form-control-lg {
        border: none;
        border-bottom: 1px solid grey;
        border-radius: 0;

    }
}



/* https://www.google.com/search?q=what+is+typescript+in+react+in+hindi&biw=1366&bih=625&tbm=vid&ei=_viJZI75HM6mseMPvc-dsAM&oq=what+is+typescript+in+react+in+hind&gs_lcp=Cg1nd3Mtd2l6LXZpZGVvEAEYADIHCCEQoAEQCjIHCCEQoAEQCjoHCAAQigUQQzoFCAAQgAQ6BggAEBYQHjoICAAQigUQhgM6BQghEKABOggIIRAWEB4QHToKCCEQFhAeEA8QHVDnA1jEI2CxLWgAcAB4AIABrAGIAbMKkgEDMC45mAEAoAEBwAEB&sclient=gws-wiz-video#fpstate=ive&vld=cid:7b7ea494,vid:c8TJmoRL67w */
/* contex */