.scroll-table{
      width:95%;
    max-height:100px;
    overflow-y:scroll;
    
}



.inword-label{
    margin-left: 50px;
}

.custom-option:hover {
    background-color: #f5f5f5;
  }

.btn2 {    margin: 2px;
    margin-left: 1px;
    width: 40%;
    z-index: 1;
}
.btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

@media only screen and (min-width: 768px) {
    .btn {
        display: flex;
        justify-content: center;
        flex-wrap: wrap
    }

   
}

@media only screen and (max-width: 768px) {
    .data {
        text-align: left;
    }

    .form {
        display: block;
        width: 100%;
        /* height: 100px; */
        width: 100%;
        text-align: left;
    }

    .redline {
        width: 100%;
    }

    .align {
        display: flex;
        flex-direction: row;
    }

    .date {
        width: 30%;
    }

   

    .table1 {
        font-size: 7px;
        width: fit-content;
        margin-bottom: 10px;    
    }
  
 
}



.inputbtn {
    margin-bottom: 2px;
}

.incolumn {
    margin: 0.25%;
}
/* th,
td,
table {
    border: 1px solid;
} */
