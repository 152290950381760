.margincard1itams{
    margin-left: 10px;
}
.margininutleft{
    margin-left: 5px;
}

table th{
    font-size: 15px;
  text-align: center;
}
.searchbar{
    height: 35px;
    right: 1%;
}
