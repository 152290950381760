.dbformbtn{
    width: 70px;
    margin: 3px;
   
 }
.dbformbtn1{
    width: 100px;
    margin: 3px;
   
 }
 .bottombtns{
    
     /* margin-top: 10px; */
     display: flex;
     flex-direction: row;
     justify-content: left;
 }
 .incolumn{
     display: flex;
     flex-direction: column;
 }
 .Radiobtn{
     display: flex;
     /* margin-right: 15px; */
 }
 .redline{
     margin-left: 1vh;
 }
 .rbtn{
     margin-left: 15vh;
     margin-right: 15vh;
 }
 .wraptable{
     flex: 1; 
   min-width: 0;
 }
 .wraptable{
     display:flex;
     flex-wrap:wrap;
 }
 .left-data{
     font-size: 12px;
 }
 .right-data{
     font-size: 12px;
 }
 .db-btns{
    display: flex;
    justify-content: flex-end;
 }
 .remark{
    display: flex;
    justify-content: flex-end;
    /* flex-direction: column; */
    margin-left: 16%;
 }

 .modal-90w {
    width: 90% !important;
    max-width: 90% !important;
  }
  
  .modal-content {
    width: 100% !important;
  }
.canvasSize {
    width: 100%;
    height: 70vh; 
}
  
 