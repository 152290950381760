/* .card2btn
{
    display: flex;
  background-color: rgb(196, 21, 21);
  color: white;
  border: 1px solid;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 25px;  
  width:90px,
    
  
    
   
} */
/* .card2btn:hover
{
    display: flex;
  background-color: rgb(196, 21, 21);
  color: white;
  border: 1px solid;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 25px; 
  text-decoration: none; 

  width:90px,
    
  
    
   
} */
.container{
  padding-left: 0%;
  padding-right: 0%;
}
.col-sm-4{
  padding-left: 0%;
  padding-right: 0%;
}
.col-8{
  padding-left: 0%;
  padding-right: 0%;
}
.Select{
  height: 25px;
}
