.mail-label{
    display: flex;
    justify-content: center;
    
}
.padding{
    padding: 10px;
}
.mail-btn{
    width: 80px;
    height: 25px;
   
    /* bottom: 0; */
}
.mail-btns{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 7%;    
}
.mail-filters{
    display: flex;
    justify-content: center;
    padding-top: 3px;
}
.fromdate{
    display: flex;
    justify-content: flex-end;
}