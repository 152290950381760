.filt{
    width: 12%;
}
.select{
    width: 100%;
}
.filt-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.filt1{
    font-size: 100%;
}
.t-count{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10%;
}
.dataheading{
    color: rgb(77, 163, 197);
    font-weight: bold;
    font-size: 12px;    
}
.datainfo{
    color: rgba(0,0,0,.87);
}
.lowdata{
    display: flex;
    flex-direction: row;
    /* max-height: 500px; */
    width: 103%;
}

.firstclm{
    width: 20%;
    border: .1px solid grey;
    padding: 20px;
}
.secondclm{
    width: 20%;
    border: .1px solid grey;
    padding: 20px;
}
.thirdclm{
    width: 60%;
    border: .1px solid grey;
    /* padding: 10px; */
}
.inline1{
    display: flex;
    justify-content: space-between;
    margin: -10px;
}
.top-data{
    /* border: .1px solid grey; */
    height: 40%;
}
.data-left{
    border: .1px solid grey;
    width: 40%;
}
.data-right{
    border: .1px solid grey;
    width: 60%;
}
.empty1{
    /* border-right: .1px solid grey; */
    width: 50%;
    height: 138px;
    background-color: white;
    /* margin-left: -15px; */
}

.status{
    border-top: .1px solid grey;
    color: blue;
    font-weight: bold;
    /* margin-left: 10px; */
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 20%;
    /* bottom: 0px; */
}
.data-radio{
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
    align-content: center;
    align-items: center;
    flex-direction: row;
    
}
.textmargin{ 
    margin-left: 2px;
}
.received{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 30px;
}
.data-right-top{
    border-bottom: .5px solid grey;
}
.data-right-bottom{
    display: flex;
    justify-content: space-evenly;
}
.textleft{
    text-align: left;
    margin-left: -10%;
    
}
.btnmt{
    margin-top: 10px;
}
.container{
    padding-left: 0px;
    padding-right: 0px;
    background-color:#e8e8e8;
  }
  