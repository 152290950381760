/* Login Activity */


#root {
  background-color: "rgba(0, 0, 0, 0.09)";
}
/* footer css */

.line {
  height: 15px;
  width: 100%;
}
.skip{
  display: flex;
}


.divHeight {
  height: 3%;
  color: aliceblue;
  text-align: end;
}

@media only screen and (max-width: 768px) {
  .divHeight {
    font-size: small;
  }
}


.name {
  left: 25%;

}

.name {
  margin-right: 25px;
  margin-left: 43px;
  display: flex;
  flex-direction: row;

}

.rightname {
  justify-content: right;
  right: 0;
}
/* header css */

.itamsHide {
  display: none;

}

.header-image {
  width: 90%;
  padding: 0px;
}

.header-image1 {

  /* margin: 5px 10px 10px 0px; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  left: 20px;
}

.navbar1 {
  display: flex;
  flex-direction: row;
  border-bottom: 5px solid #808080
  /* background-color: rgb(245, 238, 238); */
}

ul,
li {
  list-style-type: none;
}

.nav-icons {
  text-align: center;
}

.nav-icon-name {
  font-size: smaller;
  color: rgba(0,0,0,.87);
}

.nav-link {
  /* width: 90px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  /* margin: -14px; */
  /* margin-top: 10px; */
}

.nav-link1 {
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin: -14px; */
  /* margin-top: 10px; */
}

.mo-nav {
  width: 20px;
  margin-bottom: -10px;
}

.mo-mis {
  margin-top: 4px;
}

.nav-right-tabs {
  padding: 5px;
  text-align: center;
}

.form2 {
  display: flex;
  justify-content: space-evenly;
  float: right;
  align-content: center;
}

/* .form1{
display: flex;
align-items: center;
margin-top: 20px;
  } */
.inwordbtn {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .itamsHide {
    display: block;
    align-items: flex-start;
    display: flex;
    text-align: center;
    position: fixed;
    justify-content: space-evenly;
    font-size: 10px;
    width: 100%;
    bottom: -29px;
    margin-bottom: 30px;
    z-index: 2;
    background-color: rgb(253, 246, 246);
    


  }

  .inwordbtn {
    margin-left: 11%;
    background-color: red;
    padding: 5px;
    font-size: 10px;
    margin: 1%;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: row;
    

  }

  .form1 {
    display: none;

  }
}



@media only screen and (min-width: 768px) {
  .form1 {
    display: block;
  }

  .inwordbtn {
    /* color: red;
    background-color: white;
    border: 2px solid red; */
    text-align: center;
    margin: .1%;
    font-size: small;
    padding: 5px;
    border-radius: 2px;
    
  }
}

.inline {
  display: flex;
}

.right-icons {
  justify-content: flex-end;
  align-items: center;
}

.center-text {
  text-align: center;
}

.margin-top {
  margin-top: 1%;
}

.nav-pills{
  display: flex;
  flex-direction: row;
  left: 20px;
}

.sticky{
  position: sticky;
  /* position: relative; */
  top:0;
  z-index: 1;
  background-color: #fff;
}
.onTop {
  z-index: 1;
}