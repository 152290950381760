body {
  margin: 0;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    color: #fff;
    background-color: #000;
}

.table {
  color: #fff;; 
}
.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  text-align: left;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 0;
  position: relative;
  width: 85px; /* Adjust width for the labels */
  height: 28px; /* Adjust height for the labels */
}

.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  height: 100%; /* Ensure it covers the label height */
  position: relative;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 100%; /* Ensure it covers the label height */
  padding: 0;
  line-height: 22px;
  font-size: 12px;
  color: white;
  box-sizing: border-box;
  position: absolute; /* Position absolutely to fit in the container */
  top: 0;
  bottom: 0;
}

.toggle-switch-inner:before {
  content: attr(data-on);
  background-color: #db1c29;
  color: white;
  left: 0; /* Align to the left */
}

.toggle-switch-inner:after {
  content: attr(data-off);
  background-color: #58595b;
  color: #fff;
  text-align: right;
  right: 0; /* Align to the right */
}

.toggle-switch-switch {
  display: block;
  width: 18px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px; /* Adjust position for the switch handle */
  border: 2px solid #ddd;
  border-radius: 10px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
