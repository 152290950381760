.top-btn{
   height: 5%;
   width: 100%;
   
    
}
.top-btns{
    width: 10%;
    margin-bottom: -1%
}
.bottom-btn{
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
     
 }
 .bottom-btns{
     width: 10%;
     margin-bottom: 1%
 }
 .thead{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    /* z-index: 1; */
  
}
.tableContainer{ 
    height: calc(100vh - 350px);
    width: 100%;
    padding: 0 10px 10px 10px;
    overflow-y: scroll;
    
}
/* .th{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    z-index: 1;
    border: 1px solid black;
} */