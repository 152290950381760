.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body:fullscreen {
  
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: auto !important;
  background-color: #ffffffc7;
}
.fullscreen-container {
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: auto; /* Enable scrolling */
  background-color: #ffffffc7;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}
.themeColor {
  background-color: #db1c29;
  color: #fff;

}
.backgroundColor{
    background-color: rgba(0, 0, 0, 0.09);
  }
  .card {
    background-color:rgba(0, 0, 0, 0.09); 
  }
.greenBackground {
  background-color: green;
  color: rgba(0, 0, 0, 0.09);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(0, 0, 0, 0.09);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.redline{
  width: 100%;
  background-color: #db1c29;
  color: white;
height: 25;
}
.btn2 {
  margin: 2px;
  margin-left: 1px;
  width: 40%;
  z-index: 1;
  color: white;
  background-color: #db1c29;
  border: #db1c29;    
  text-decoration: none;
}
/* Add in App.css */
.btn2:hover {
  color: white;
  background-color: #db1c29;
  border: #db1c29;
  text-decoration: none;
}
.inwordbtn {
  background-color: #db1c29;
  color: white;
  font-weight: 600;
  /* color: #db1c29;
    background-color: rgba(0, 0, 0, 0.09);
    border: 2px solid #db1c29; */
    
}
.line{
  background-color: #db1c29;
  background-image: linear-gradient(to right, #db1c29 80%, grey 20%);
}
.thead{
  color: white;
  background-color: #db1c29;
  /* border: 2px solid #db1c29; */
}
  table.dataTable tbody tr.selected {
    color: white;
    background-color: #db1c29;
}
.table tbody td {
padding: 0.10rem;
vertical-align:middle;
text-wrap: wrap;
}
.table tr td, .table tr th {
  padding: 0.25rem;
  border: 1px solid #ddd; /* Add borders for better visibility */
}
.pagination {
  float: right;
}
.container, .outerborder {
  border:  1px solid #ccc;
}
.height25 {
  height: "25px";
}
.fixedBottom {
  bottom:  20px;
  position: sticky;
  z-index: 1000;  
}
.hideHeader {
  top: 0px
}
.dbformbtn,
.dbformbtn1
{
  background-color: rgb(196, 21, 21);
  color: white;
  border: rgb(196, 21, 21);
}
.App{
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
Link{
  text-decoration: none;
  color: rgb(196, 21, 21);
}
 .inwordbtn:hover{
    text-decoration: none;
    color: rgb(196, 21, 21);
    background-color: rgb(253, 246, 246);
  }
  .nav-icon-name:hover{
    text-decoration: none;
    
  }
  /* #my-component{
    height: 100;
    overflow-x:scroll;
  } */
  .rowWidth70{
    width: 70%;
  }
  .rowWidth10 {
    width: 10%;
  }
  .rowWidth20 {
    width: 10%;
  }
  .fa {
    margin-right: 10px;
  }
  .select {
    padding-left: 0px;
padding-right: 0px;
  }
  .selectPadding {
    padding: 0;
  }
  /* Apply horizontal scrolling for the table */
.table-responsive {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
} 
.showinline {
  overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
  max-width: 250px;
}
/* Enable auto-adjusting column widths */
.react-bootstrap-table th,
.react-bootstrap-table td {
  white-space: nowrap;
}

/* Optionally set a maximum width for specific columns */
.react-bootstrap-table .fixed-width-col {
  max-width: 200px; /* Set the desired maximum width */
}
  .react-bootstrap-table table { table-layout: auto !important; 
    overflow-x: auto; }
  
  .css-13uhuhe-control{
    margin-bottom: 1%;
    height: 25px;
    border-color:grey;
  }
  input{
    border:.5px solid hsl(0, 0%, 80%);
    background-color: #ffffffc7;
  }
  textarea {
    background-color: #ffffffc7;
  }
  canvas{
    background-color: #ffffffc7;
  }
  

  .justify-content-center {
    padding-bottom:  30px;
  }
  .card{
    display: flex;
    align-items: center;
    width: 75%;
  margin-top: 7px; 
  justify-content: center;  
  background-color: none;
}
.redline3{
    height: 30px;
    width: 75%;
    box-sizing: 10px;
}
.inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3px;
}
.btn2{  
    margin: 10px;
    border: .5px solid;    
    padding: auto;
    font-size: small;
}
.btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}
@media only screen and (max-width: 768px) {

  .redline3{
      width: 100%;
  }
  .card{
      width: 100%;
  }
}
.swal-fire-alert {
  font-size: 18px; /* Adjust the font size as needed */
  color: #db1c29; /* Adjust the font color as needed */
}
.btn-red {
  background-color: #db1c29 !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff; 
  background-color: #58595b !important;
  border-color: #58595b !important;
}
.page-link {
  color: #fff  !important;
  background-color:  #db1c29 !important;
  border: 1px solid #db1c29 !important;;
}
.font-size-14 {
  font-size:  14px;
}
/* Style for the suggestion list */
.suggestion-list {
  list-style-type: none; /* Remove default list bullet points */
  padding: 0;
  margin: 0;
  max-height: 150px; /* Set a max height for the suggestion list to make it scrollable */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
  border: 1px solid #ccc; /* Add a border for separation */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Style for each suggestion item */
.suggestion-item {
  padding: 8px 12px;
  cursor: pointer; /* Make the items clickable */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

/* Highlight the suggestion item on hover */
.suggestion-item:hover {
  background-color: #f0f0f0;
}
.active-link { 
  background-color: #58595b; 
  color: white !important;
  font-weight: 600;
}